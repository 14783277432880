var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto", attrs: { "max-width": "219" } },
    [
      _vm.voucher.prize.photo_url
        ? _c("v-img", {
            attrs: {
              src: _vm.voucher.prize.photo_url,
              height: _vm.prizeHeightImg
            }
          })
        : _c(
            "div",
            {
              staticClass:
                "d-flex justify-center align-center media-placeholder grey lighten-2"
            },
            [_c("v-icon", { attrs: { large: "" } }, [_vm._v("fas fa-image")])],
            1
          ),
      _c("v-divider"),
      _c(
        "v-card-title",
        { staticClass: "d-flex" },
        [
          _c("span", [_vm._v(_vm._s(_vm.voucher.prize.name))]),
          _c("v-spacer"),
          _vm.showPrice
            ? _c(
                "span",
                {
                  class: _vm.isLightTheme
                    ? "grey--text text--darken-1"
                    : "primary--text"
                },
                [_vm._v(_vm._s(_vm.computedPriceText))]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-subtitle",
        [
          _c("span", { staticClass: "caption" }, [
            _vm._v(_vm._s(_vm.additionalText))
          ]),
          _vm.showDivier
            ? _c("v-divider", { staticClass: "mt-2 mb-4" })
            : _vm._e(),
          _vm.showDescription
            ? _c("span", [_vm._v(_vm._s(_vm.voucher.prize.description))])
            : _vm._e()
        ],
        1
      ),
      _vm.showActions
        ? _c(
            "v-card-subtitle",
            { staticClass: "pa-0 text-center" },
            [
              _vm.showDivier ? _c("v-divider") : _vm._e(),
              _c("v-spacer"),
              _c(
                "v-chip",
                {
                  staticClass: "my-2 px-4",
                  attrs: {
                    color: _vm.isLightTheme ? "orange darken-1" : "primary",
                    label: "",
                    outlined: ""
                  }
                },
                [
                  _c("span", { staticClass: "subtitle-1" }, [
                    _vm._v(_vm._s(_vm.voucher.getCodeString()))
                  ])
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }