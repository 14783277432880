var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-start" },
    [
      _c("v-icon", { staticClass: "mr-2 ml-n2 mt-2", attrs: { size: 40 } }, [
        _vm._v("$cp_balance")
      ]),
      _c("div", { staticClass: "d-flex flex-column" }, [
        _c("span", { staticClass: "secondary_font--text subtitle-2" }, [
          _vm._v(" " + _vm._s(_vm.$t("views.HomeView.balance.title")) + " ")
        ]),
        _c(
          "span",
          {
            staticClass: "primary--text",
            class: _vm.$vuetify.breakpoint.smAndDown ? "text--h5" : "text--h6"
          },
          [_c("Balance")],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }