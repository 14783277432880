<template>
    <v-card
        class="mx-auto"
        max-width="219"
    >
        <v-img
            v-if="voucher.prize.photo_url"
            :src="voucher.prize.photo_url"
            :height="prizeHeightImg"
        ></v-img>
        <div v-else class="d-flex justify-center align-center media-placeholder grey lighten-2">
            <v-icon large>fas fa-image</v-icon>
        </div>

        <v-divider></v-divider>

        <v-card-title class="d-flex">
            <span>{{ voucher.prize.name }}</span>
            <v-spacer></v-spacer>
            <span v-if="showPrice" :class="isLightTheme ? 'grey--text text--darken-1' : 'primary--text'">{{ computedPriceText }}</span>
        </v-card-title>
        <v-card-subtitle>
            <span class="caption">{{ additionalText }}</span>
            <v-divider v-if="showDivier" class="mt-2 mb-4"></v-divider>

            <span v-if="showDescription">{{ voucher.prize.description }}</span>
        </v-card-subtitle>

        <v-card-subtitle v-if="showActions" class="pa-0 text-center">
            <v-divider v-if="showDivier"></v-divider>

            <v-spacer></v-spacer>
            <v-chip class="my-2 px-4" :color="isLightTheme ? 'orange darken-1' : 'primary'" label outlined>
                <span class="subtitle-1">{{ voucher.getCodeString() }}</span>
            </v-chip>
        </v-card-subtitle>
    </v-card>
</template>

<script>
import Voucher   from '@/models/Voucher'
import RateColor from '@/shared/mixins/RateColor'
import moment    from 'moment'

export default {
    name: 'VoucherCard',
    mixins: [ RateColor ],
    props: {
        voucher: {
            type: Voucher,
            required: true,
        },
        showActions: {
            type: Boolean,
            default: false,
        },
        priceText: {
            type: String,
            required: false,
        },
        showDescription: {
            type: Boolean,
            required: false,
            default: true,
        },
        prizeHeightImg: {
            type: [String, Number],
            required: false,
            default: 250,
        },
        showDivier: {
            type: Boolean,
            required: false,
            default: true,
        },
        showPrice: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    data: () => {
        return {
            additionalText: '',
        }
    },
    created() {
        this.setAdditionalText()
    },
    methods: {
        details() {
            
        },

        setAdditionalText() {
            // [TODO] Internacionalizar datas
            if (this.voucher.isWithdrawable())
                this.additionalText = this.voucher.expires_at ? this.$t('views.Prizes.tabs.history_withdrawable.voucher_card.expires_at', { expires_at: moment(this.voucher.expires_at).format('DD/MM/YYYY') }) : this.$t('views.Prizes.tabs.history_withdrawable.voucher_card.never_expires')
            else if (this.voucher.withdrawn_at)
                this.additionalText = this.$t('views.Prizes.tabs.history_withdrawn.voucher_card.withdrawn_at', { withdrawn_at: moment(this.voucher.withdrawn_at).format('DD/MM/YYYY') })
            else    
                this.additionalText = this.$t('views.Prizes.tabs.history_expired.voucher_card.expired_at', { expired_at: moment(this.voucher.expires_at).format('DD/MM/YYYY') })
        },
    },
    computed: {
        computedPriceText() {
            return this.priceText || this.$options.filters.currency(this.voucher.prize.value);
        },
    },
}
</script>

<style lang="scss" scoped>
.media-placeholder {
    height: 250px;
}
</style>