<template>
    <div class="d-flex align-start">
        <v-icon class="mr-2 ml-n2 mt-2" :size="40">$cp_balance</v-icon>
        <div class="d-flex flex-column">
            <span class="secondary_font--text subtitle-2">
            {{ $t('views.HomeView.balance.title') }}
        </span>
        <span class="primary--text" :class="$vuetify.breakpoint.smAndDown ? 'text--h5' : 'text--h6' ">
            <Balance />
        </span>
        </div>
    </div>
</template>

<script>
import Balance from './Balance'

export default {
    name: 'CustomerBalance',
    components: { Balance },
}
</script>

<style scoped>
.text--h5{
    font-size: 1.5rem;
    margin-top: 0;
}

.text--h6{
    font-size: 1.25rem;
    margin-top: -.2rem;
}

</style>